import React, { useState } from 'react'
import { Form, Input, Button, Radio, Modal, Select, DatePicker, Table, notification, Result, Card, Checkbox } from 'antd'
import './App.css'
import styled from '@emotion/styled'
import InputMask from 'react-input-mask'
import Axios from 'axios'
import { getYear, getMonth, isAfter, isSameDay, parseISO } from 'date-fns'
import * as _ from 'lodash'
import { ExclamationCircleOutlined, CheckCircleFilled, HourglassOutlined, PrinterTwoTone } from '@ant-design/icons'
import moment from 'moment'

const meses = 'Janeiro,Fevereiro,Março,Abril,Maio,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro'.split(',')

const ContribuicaoSindical = () => {
  const anoAtual = new Date().getFullYear()

  const [cnpj, setCnpj] = useState('')
  const [conta, setConta] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [showingNovaContribuicao, setShowingNovaContribuicao] = useState(false)
  const [mesSelecionado, setMesSelecionado] = useState(new Date().getMonth())
  const [anoSelecionado, setAnoSelecionado] = useState(anoAtual)
  const [vencimentoSelecionado, setVencimentoSelecionado] = useState(moment().add(7, 'days') as any)
  const [valorContribuicao, setValorContribuicao] = useState('0,00')
  const [cobrancaRecemGerada, setCobrancaRecemGerada] = useState<any>(null)
  const [contribuicaoSindicalFidelizada, setContribuicaoSindicalFidelizada] = useState(true)

  function handleChangeCnpj (event) {
    const _cnpj = event.target.value
    setCnpj(_cnpj)
    if (_cnpj.replace(/[/.\-_]/g, '').length === 14) getContaByCnpj(_cnpj)
  }

  async function getContaByCnpj (_cnpj) {
    _cnpj = _cnpj.replace(/[/.\-_]/g, '')
    if (isLoading) return
    if (_cnpj.length !== 14) return notification.open({ message: 'Informe o CNPJ completo' })
    setIsLoading(true)
    try {
      const dadosConta = await Axios.get(`/cnpj/${_cnpj}`).then(x => x.data)
      if (!dadosConta) {
        notification.open({
          message: 'CNPJ Inexistente',
          description: 'Contate o suporte: 0800 009 0910 (Ligação telefônica e WhatsApp)',
        })
      }
      setConta(dadosConta)
    } catch (error) {
      const description = _.get(error, 'response.data.message')
      notification.open({
        message: 'Erro ao buscar a CONTA',
        description,
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      })
    } finally {
      setIsLoading(false)
    }
  }

  function handleChangeValorContribuicao (event) {
    setValorContribuicao(_formatValor(event.target.value))
  }

  async function handleConfirmModal () {
    setIsLoading(true)
    try {
      const dataContribuicao = new Date()
      dataContribuicao.setFullYear(anoSelecionado)
      dataContribuicao.setMonth(mesSelecionado)
      const cobrancaCriada = await Axios.post('/boleto/contribuicao-sindical', {
        valorContribuicao: contribuicaoSindicalFidelizada ? 150.00 : +valorContribuicao.replace(/[.]/g, '').replace(',', '.'),
        dataContribuicao,
        contaId: conta.Id,
        vencimento: vencimentoSelecionado.toDate(),
        contribuicaoSindicalFidelizada: contribuicaoSindicalFidelizada,
      }).then(x => x.data)

      setCobrancaRecemGerada(cobrancaCriada)
      setShowingNovaContribuicao(false)
      await getContaByCnpj(cnpj)
    } catch (error) {
      const description = _.get(error, 'response.data.message')
      notification.open({
        message: 'Erro ao gerar o boleto',
        description,
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      })
    } finally {
      setIsLoading(false)
    }
  }

  function handleCancelModal () {
    if (isLoading) {
      return notification.open({
        message: 'Por favor aguarde a emissão do boleto',
      })
    }
    setShowingNovaContribuicao(false)
  }

  function handleAbrirBoleto () {
    _showBoleto(cobrancaRecemGerada)
    setCobrancaRecemGerada(null)
  }

  function handleCancelar () {
    setConta({})
    setCnpj('')
  }

  function handleChangeContribuicao () {
    setContribuicaoSindicalFidelizada(!contribuicaoSindicalFidelizada)
  }

  return (
    <Fundo>

      <Styles.Card title="Contribuição Sindical">
        <Show condition={!conta.Id}>
          <h2>Informe o CNPJ</h2>
          <Form layout="vertical">
            <Form.Item>
              <InputMask mask="99.999.999/9999-99" value={cnpj} onChange={handleChangeCnpj}>
                {(inputProps) => <Input size="large" placeholder="CNPJ" {...inputProps} />}
              </InputMask>
            </Form.Item>
            <Form.Item>
              <Button size="large" loading={isLoading} block type="primary" onClick={() => getContaByCnpj(cnpj)}>
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </Show>

        <Show condition={conta.Id}>
          <h1 style={{ borderBottom: '1px solid #1890ff' }}>
            {conta.Fantasia}
          </h1>
          <ContainerFlex>
            <h2>{conta.CNPJ}</h2>
            <h2>{conta.Municipio} - {conta.UF}</h2>
          </ContainerFlex>
          <ContainerFlexButtons>
            <div>
              <Button size="large" block type="primary" onClick={() => setShowingNovaContribuicao(true)}>
                Nova contribuição
              </Button>
            </div>
            <Button size="large" onClick={handleCancelar}>
              Cancelar
            </Button>
          </ContainerFlexButtons>
          <ContribuicoesAntigas data={conta.BoletosContribuicaoSindical || []} />
        </Show>
      </Styles.Card>

      <Modal title="Nova contribuição" visible={showingNovaContribuicao} onOk={handleConfirmModal} onCancel={handleCancelModal}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={handleConfirmModal}>
            Ok
          </Button>,
          <Button key="back" onClick={handleCancelModal}>
            Cancelar
          </Button>,
        ]}>

        <div style={{ marginBottom: '15px' }}>
          <label >
            <Checkbox checked={contribuicaoSindicalFidelizada} onChange={handleChangeContribuicao} style={{ marginRight: 4 }} />
            Contribuição sindical fidelizada
          </label>
        </div>

        <Form.Item label="Ano" colon={false}>
          <Radio.Group size="large" onChange={e => setAnoSelecionado(e.target.value)} defaultValue={anoAtual}>
            <Radio.Button value={anoAtual - 1}>{anoAtual - 1}</Radio.Button>
            <Radio.Button value={anoAtual} >{anoAtual}</Radio.Button>
            <Radio.Button value={anoAtual + 1}>{anoAtual + 1}</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Mês" colon={false}>
          <Select size="large" defaultValue={mesSelecionado} style={{ width: '100%' }} onChange={event => setMesSelecionado(event)}>
            {meses.map((mes, index) => <Select.Option key={index} value={index}>{mes}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item label="Valor" colon={false}>
          <Input
            size="large"
            addonBefore="R$ "
            placeholder="0,00"
            disabled={contribuicaoSindicalFidelizada}
            value={contribuicaoSindicalFidelizada ? '150,00' : valorContribuicao}
            onChange={handleChangeValorContribuicao} />
        </Form.Item>
        <Form.Item label="Vencimento" colon={false}>
          <DatePicker allowClear={false}
            format="DD/MM/YYYY"
            defaultValue={vencimentoSelecionado}
            onChange={(date) => setVencimentoSelecionado(date)}
            disabledDate={(currDate) => !!(currDate.isBefore(moment()) || currDate.isAfter(moment().add(90, 'days')))} />
        </Form.Item>

        <Show condition={!contribuicaoSindicalFidelizada}>
          <ContainerFlex style={{ marginBottom: 12 }}>
            <div style={{ width: 300, textAlign: 'right' }}>Tarifa Bancária</div>
            <SeparadorValorBoleto />
            <div>R$ 2,90</div>
          </ContainerFlex>
          <ContainerFlex>
            <div style={{ width: 300, textAlign: 'right' }}>Valor do Boleto</div>
            <SeparadorValorBoleto />
            <div>R$ {(+valorContribuicao.replace(/[.]/g, '').replace(',', '.') + 2.9).toFixed(2).replace('.', ',')}</div>
          </ContainerFlex>
        </Show>

        <Show condition={contribuicaoSindicalFidelizada}>
          <ContainerFlex style={{ marginBottom: 12 }}>
            <div style={{ width: 300, textAlign: 'right' }}>MANUTENÇÃO</div>
            <SeparadorValorBoleto />
            <div>R$ 30,00</div>
          </ContainerFlex>

          <ContainerFlex style={{ marginBottom: 12 }}>
            <div style={{ width: 300, textAlign: 'right' }}>SINPOCEFC-GO</div>
            <SeparadorValorBoleto />
            <div>R$ 117,10</div>
          </ContainerFlex>

          <ContainerFlex style={{ marginBottom: 12 }}>
            <div style={{ width: 300, textAlign: 'right' }}>Tarifa Hyperpay</div>
            <SeparadorValorBoleto />
            <div>R$ 2,90</div>
          </ContainerFlex>
          <ContainerFlex>
            <strong style={{ width: 300, textAlign: 'right' }}>Valor Total do Boleto</strong>
            <SeparadorValorBoleto />
            <strong>R$ 150,00</strong>
          </ContainerFlex>
        </Show>
      </Modal>

      <Modal visible={!!cobrancaRecemGerada} onCancel={() => setCobrancaRecemGerada(null)} footer={null}>
        <Result
          status="success"
          title="Boleto gerado com sucesso!"
          subTitle="Clique aqui para abrir o boleto"
          extra={[
            <Button key="console" onClick={handleAbrirBoleto}>
              Abrir boleto
            </Button>,
          ]}
        />,
      </Modal>
    </Fundo>
  )
}

function ContribuicoesAntigas ({ data }) {
  if (!data || !data.length) return null

  const defaultSortOrder: 'descend' | 'ascend' | undefined = 'descend'

  const columns = [{
    title: 'Referência',
    key: 'ContribuicaoSindical.Data',
    defaultSortOrder,
    sorter: (a, b) => a.ContribuicaoSindical.Data > b.ContribuicaoSindical.Data ? 1 : -1,
    render: _renderReferencia,
  }, {
    title: 'Valor',
    className: 'align-right',
    dataIndex: 'Valor',
    key: 'Valor',
    sorter: (a, b) => a.Valor - b.Valor,
    render: _renderValor,
  }, {
    title: 'Status',
    key: 'Status',
    render: _renderStatus,
  }, {
    render: _renderPrinter,
    onCell: (record) => ({ onClick: () => _showBoleto(record) }),
    className: 'printer-cell',
  }]

  return (
    <div>
      <h1 style={{ borderBottom: '1px solid #1890ff' }}> Contribuições anteriores </h1>
      <Table columns={columns} dataSource={data} pagination={{ defaultPageSize: 5 }} />
    </div>
  )
}

function _renderReferencia (_, record) {
  const data = parseISO(record.ContribuicaoSindical.Data)
  const mes = meses[getMonth(data)]
  const ano = getYear(data)
  return <div> {mes} de {ano} </div>
}

function _renderValor (valor, record) {
  return <div style={{ textAlign: 'right' }}>R$ {valor.toFixed(2).replace('.', ',')}</div>
}

function _renderStatus (_, record) {
  if (record.Pago) return <div><CheckCircleFilled style={{ color: 'green' }} /> Pago</div>
  if (_isBoletoVencido(record.Vencimento)) return <div><ExclamationCircleOutlined style={{ color: 'red' }} /> Vencido</div>
  return <div><HourglassOutlined style={{ color: 'black' }} /> Pendente</div>
}

function _renderPrinter (_, record) {
  return <PrinterTwoTone style={{ opacity: _isBoletoVencido(record.Vencimento) || record.Pago ? 0.3 : 1 }} />
}

function Show ({ condition, children }) {
  return condition ? children : null
}

function _padLeft (valor, tamanho, caracter) {
  return (('' + caracter).repeat(tamanho) + valor).slice(-Math.max(tamanho, ('' + valor).length))
}

function _formatValor (valor) {
  valor = _padLeft(+valor.replace(/[.,]/g, ''), 3, 0)
  const decimal = valor.slice(-2)
  let inteiro = valor
    .slice(0, -2)
    .split('').reverse().join('')
    .replace(/\d{3}/g, a => a + '.')
    .split('').reverse().join('')
  inteiro = inteiro.charAt(0) === '.' ? inteiro.slice(1) : inteiro

  return inteiro + ',' + decimal
}

function _showBoleto (responseCobranca) {
  if (_isBoletoVencido(responseCobranca.Vencimento)) {
    return notification.open({
      message: 'Boleto está vencido!',
    })
  }
  if (responseCobranca.Pago) {
    return notification.open({
      message: 'Boleto já está pago!',
    })
  }

  window.open(responseCobranca.Boleto_URL)
}

function _isBoletoVencido (dataVencimento) {
  if (isSameDay(new Date(), parseISO(dataVencimento.replace('Z', '')))) return false
  return isAfter(new Date(), parseISO(dataVencimento.replace('Z', '')))
}

const Fundo = styled.div`
  background: #efefef;
  height: 100vh;
  padding-top: 32px;
`

const Styles = {
  Card: styled(Card)`
    background: white;
    border-radius: 4px;
    box-shadow: 3px 3px 5px rgba(0,0,0,.25);
    max-width: 800px;
    margin: 0 auto;
    max-height: 80vh;
    overflow: auto;
  `,
}

const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContainerFlexButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  &>div {
    flex: 1;
    margin-right: 24px;
  }
`

const SeparadorValorBoleto = styled.div`
  flex: 1;
  border-bottom: 1px solid #d9d9d9;
  margin: 0 12px 5px;
`

export { ContribuicaoSindical }
