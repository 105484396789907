import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { BoletoTeorico } from './BoletoTeorico'
import { ContribuicaoSindical } from './ContribuicaoSindical'
import Axios from 'axios'
import logoSinpocefc from './assets/logo-sinpocefc.png'
import { Radio } from 'antd'

Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
// ? 'https://webservices.hyperpay.com.br/sinpocefc'
// : 'http://localhost:3502/sinpocefc'

// const CURSO_TEORICO_URL = 'https://hyperpay-curso-teorico-public.netlify.app/agendamentos'

const App = () => {
  const [comp, setComp] = useState<any>('')

  return (
    <Styles.Container>
      <div className="topo">
        <img src={logoSinpocefc} alt="logo sinpocefc" />
        {/* <div className="links">
          <Styles.Link href="#" active={comp === 'BOLETO_TEORICO'} onClick={() => setComp('BOLETO_TEORICO')} >
            Boleto Teórico
          </Styles.Link>
          <Styles.Link href="#" active={comp === 'CONTRIBUICAO_SINDICAL'} onClick={() => setComp('CONTRIBUICAO_SINDICAL')} >
            Contribuição Sindical
          </Styles.Link>

        </div> */}
        {comp === '' && <IndicacaoSelecao />}
        <div className="links">
          <Radio.Group value={comp} buttonStyle="solid" onChange={e => setComp(e.target.value)} size="large" style={{ width: '100%' }}>
            <Radio.Button value="BOLETO_TEORICO">Curso Teórico</Radio.Button>
            <Radio.Button value="MATRICULA">Matrícula</Radio.Button>
            <Radio.Button value="CONTRIBUICAO_SINDICAL">Contribuição Sindical</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      {comp === 'BOLETO_TEORICO' && <BoletoTeorico />}
      {comp === 'MATRICULA' && <Matricula />}
      {comp === 'CONTRIBUICAO_SINDICAL' && <ContribuicaoSindical />}
    </Styles.Container>
  )
}

const Matricula = () => {
  useEffect(() => {
    window.location.href = 'https://hyperpay-monitoramento-matricula.netlify.app/'
  })
  return null
}

const IndicacaoSelecao = () => {
  return (
    <div style={{ margin: 48 }}>
      <h1 style={{ display: 'inline', borderBottom: '3px solid #1890ff' }}>
        Escolha qual o tipo de boleto deseja emitir:
      </h1>
    </div>
  )
}

const Styles = {
  Container: styled('div')`
    background: #efefef;
    min-height: 100vh;
    .topo {
      text-align: center;
    }
    .links {
      max-width: 800px;
      margin: 0 auto;
      margin-top: 24px;
      .ant-radio-group {
        width: 100%;
        display: flex;
        .ant-radio-button-wrapper {
          flex: 1;
        }
      }
    }
    img {
      margin-top: 24px;
    }
  `,
  Link: styled('a')<{ active }>(props => `
    ${props.active
      ? `
        font-weight: bold;
        color: white;
        background: #40a9ff;
      `
      : ''}
    font-size: 1.3em;
    padding: 12px 14px;
    display: inline-block;
    &:hover, &:active {
      text-decoration: underline;
    }
  `),
}

export { App }
