import React, { useEffect, useState } from 'react'
import { Input, Select, Card, Button, notification, Modal, Checkbox } from 'antd'
import useAxios from 'axios-hooks'
import Axios from 'axios'
import styled from '@emotion/styled'
import { createSimpleStore } from 'react-simple-reducer'
import { createSelector } from 'reselect'
import { TextFieldInput } from './TextField'
import { isValid as isCpfValid } from '@fnando/cpf'

const CONTA_ID_CFC_LAPAZ = 855
const CURSO_TEORICO_URL = 'https://hyperpay-curso-teorico-public.netlify.app/agendamentos'

interface ContaModel {
  Id: number
  Status: string
  CNPJ: string
  Fantasia: string
  RazaoSocial: string
  DataCadastro: string
  eMail: string
  TelefoneFixo: string
  CEP: string
  Endereco: string
  Numero: string
  Complemento: string
  Setor: string
  Municipio: string
  UF: string
  SocioAdmin_Nome: string // eslint-disable-line
  SocioAdmin_CPF: string // eslint-disable-line
  SocioAdmin_Fone: string // eslint-disable-line
  Logo: string
  Tipo: string
  UsuarioConta// : Usuario
  Conta_Id_Split: number // eslint-disable-line
  BoletosContribuicaoSindical// : Boleto[]
  Emplacador// : Emplacador
  Fornecedor// : Fornecedor
}

const Store = createSimpleStore({
  contas: [] as ContaModel[],
  municipio: 'GOIÂNIA',
  contaOrigem: null as any,
  contaDestino: null as any,
  cpf: '',
  nome: '',
  possuiRepasseOrigem: !!localStorage.getItem('possui-repasse-origem'),
  contasSocias: {} as { [contaDestinoId: number]: { Conta_Id: number, Desconto: number }[] } // eslint-disable-line
}, {
  setContas (state, payload: { contas: ContaModel[] }) {
    state.contas = payload.contas
  },
  changeMunicipio (state, payload: string) {
    state.municipio = payload
    if (state.municipio === 'JATAÍ') state.possuiRepasseOrigem = true
    state.contaDestino = null
  },
  changeDestino (state, payload) {
    state.contaDestino = payload
  },
  changeOrigem (state, payload) {
    state.contaOrigem = payload
  },
  changeCpf (state, payload: string) {
    state.cpf = payload
  },
  changeNome (state, payload: string) {
    state.nome = payload
  },
  clear (state) {
    state.contaOrigem = null
    state.contaDestino = null
    state.cpf = ''
    state.nome = ''
  },
  changePossuiRepasse (state, payload: boolean) {
    state.possuiRepasseOrigem = payload
  },
  getContasSociasSuccess (state, contasSocias) {
    state.contasSocias = contasSocias
  },
}, {
  thunks: {
    getContas () {
      return async (dispatch) => {
        const contas = await Axios.get('/cfcs-destino').then(x => x.data)
        dispatch(Store.actions.setContas({ contas }))
      }
    },
    getContasSocias () {
      return async dispatch => {
        const contasSocias = await Axios.get('/cfcs-socios').then(x => x.data)
        dispatch(Store.actions.getContasSociasSuccess(contasSocias))
      }
    },
  },
})

type IState = ReturnType<typeof Store.useState>
const selectMunicipios = createSelector(
  (s: IState) => s.contas,
  (contas) => {
    return Array.from(new Set(contas.map(x => x.Municipio)))
  }
)

const selectContas = createSelector(
  (s: IState) => s.contas,
  (s: IState) => s.municipio,
  (contas, municipio) => {
    return [...contas
      .filter(x => x.Municipio === municipio)]
      .sort((a, b) => {
        return a.Fantasia > b.Fantasia ? 1 : -1
      })
  }
)
const BoletoTeorico = () => {
  const { Container, CardCfc, CardAluno } = BoletoTeorico

  useEffect(() => {
    window.location.href = CURSO_TEORICO_URL
  }, [])

  return null
  // return (
  //   <Container>
  //     <Store.Provider>
  //       <form>
  //         <CardCfc title="CFC de origem e destino">
  //           <Municipio />
  //           <CfcOrigem />
  //           <CfcDestino />
  //           <CheckPossuiRepasseOrigem />
  //           <Repasses />
  //         </CardCfc>

  //         <CardAluno title="Aluno">
  //           <Cpf />
  //           <Nome />
  //         </CardAluno>

  //         <GerarBoleto />
  //       </form>
  //       <Init />
  //     </Store.Provider>
  //   </Container>
  // )
}
BoletoTeorico.Card = styled(Card)`
  background: white;
  border-radius: 4px;
  box-shadow: 3px 3px 5px rgba(0,0,0,.25);
  max-width: 800px;
  margin: 32px auto;
`
BoletoTeorico.Container = styled('div')`
  background: #efefef;
  height: 100%;
`
BoletoTeorico.CardCfc = styled(BoletoTeorico.Card)`
  .ant-card-body {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-areas:
      "municipio municipio"
      "cfc-origem cfc-destino"
      "check-possui-repasse-origem check-possui-repasse-origem"
      "repasses repasses";
    .municipio { grid-area: municipio }
    .cfc-origem { grid-area: cfc-origem }
    .cfc-destino { grid-area: cfc-destino }
    .check-possui-repasse-origem { grid-area: check-possui-repasse-origem }
    .repasses {grid-area: repasses }
    .nome-cfc {
      font-weight: bold;
      font-size: 1.3em;
    }
    @media (max-width:750px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "municipio"
        "cfc-origem"
        "cfc-destino"
        "check-possui-repasse-origem"
        "repasses";
    }
  }
`
BoletoTeorico.CardAluno = styled(BoletoTeorico.Card)`
  .ant-card-body {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 12px;
    grid-template-areas:
      "cpf nome";
    .cpf { grid-area: cpf }
    .nome { grid-area: nome }
    @media (max-width:750px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "cpf"
        "nome";
    }
  }
`

const Init = () => {
  const dispatch = Store.useDispatch()
  useEffect(() => {
    dispatch(Store.thunks.getContas())
    dispatch(Store.thunks.getContasSocias())
  }, [])
  return null
}

const Municipio = () => {
  const state = Store.useState()
  const dispatch = Store.useDispatch()
  const municipios = selectMunicipios(state)

  function handleChange (value) {
    dispatch(Store.actions.changeMunicipio(value))
  }

  return (
    <div className="municipio">
      <label>
        Informe o Município onde será realizado o curso<br />
        <Select
          value={state.municipio}
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          {municipios.map(x => <Select.Option key={x} value={x}>{x}</Select.Option>)}
        </Select>
      </label>
    </div>
  )
}

const CfcOrigem = () => {
  const [cnpj, setCnpj] = useState('')
  const dispatch = Store.useDispatch()
  const [{ data, loading }, reqCfcOrigem] = useAxios(`/cfc-origem?cnpj=${cnpj}`, { manual: true })
  const { contaOrigem } = Store.useState()
  useEffect(() => {
    if (cnpj.replace(/[_./-]/g, '').length === 14) reqCfcOrigem()
  }, [cnpj, reqCfcOrigem])
  useEffect(() => {
    if (data) dispatch(Store.actions.changeOrigem(data))
  }, [data])
  useEffect(() => {
    if (!contaOrigem) setCnpj('')
  }, [contaOrigem])
  return (
    <div className="cfc-origem">
      <label>
        CFC de Origem
        <TextFieldInput
          mask="cnpj"
          placeholder="Informe o CNPJ do CFC de Origem"
          value={cnpj}
          onChange={e => setCnpj(e.target.value)}
          loading={!!loading}
          size="large"
          autoFocus={true}
        />
      </label>
      {contaOrigem && (
        <div>
          <div className="nome-cfc"> {contaOrigem.Fantasia} </div>
          <div> Município: <strong>{contaOrigem.Municipio}</strong></div>
        </div>
      )}
    </div>
  )
}

const CfcDestino = () => {
  const state = Store.useState()
  const dispatch = Store.useDispatch()
  const contas = selectContas(state)

  function handleChange (value) {
    const cfcDestino = contas.find(x => x.Id === value)
    dispatch(Store.actions.changeDestino(cfcDestino))
  }

  return (
    <div className="cfc-destino">
      <label>
        CFC de Destino<br />
        <Select
          onChange={handleChange}
          value={state.contaDestino?.Id || ''}
          size="large"
          style={{ width: '100%' }}
        >
          <Select.Option value={''}><em>Informe o CFC de destino</em></Select.Option>
          {contas.map(c => <Select.Option key={c.Id} value={c.Id}>{c.Fantasia}</Select.Option>)}
        </Select>
      </label>
    </div>
  )
}

const CheckPossuiRepasseOrigem = () => {
  const dispatch = Store.useDispatch()
  const { possuiRepasseOrigem, municipio } = Store.useState()
  const { Container } = CheckPossuiRepasseOrigem

  function handleChange (e) {
    dispatch(Store.actions.changePossuiRepasse(!!e.target.checked))
  }

  if (municipio === 'JATAÍ') return null

  return (
    <Container className="check-possui-repasse-origem">
      <label>
        <Checkbox checked={possuiRepasseOrigem} onChange={handleChange} style={{ marginRight: 4 }} />
        Repasse ao CFC de origem
      </label>
    </Container>
  )
}
CheckPossuiRepasseOrigem.Container = styled.div`
  text-align: right;
`

const Repasses = () => {
  const { municipio, contaDestino, contaOrigem, possuiRepasseOrigem, contasSocias } = Store.useState()

  // #region Funções auxiliares
  const _getDadosContaSocia = () => {
    const contaSocia = contasSocias[contaDestino?.Id]?.find(x => x.Conta_Id === contaOrigem?.Id)
    return contaSocia
  }
  const _getRepasseContaDestino = ({ valorRepasseMonitoramento }) => {
    let valor = contaDestino?.Id === CONTA_ID_CFC_LAPAZ ? 110 : 120
    const contaSocia = _getDadosContaSocia()
    if (contaSocia) valor = valor - contaSocia.Desconto
    if (municipio === 'JATAÍ') {
      if (contaOrigem?.CNPJ === '07.110.727/0001-70' || contaOrigem?.CNPJ === '04.197.488/0001-40') {
        valor = 468
      } else {
        valor = 368
      }
    }

    return [{ descricao: contaDestino?.Fantasia ?? <em>CFC de Destino</em>, valor: valor - valorRepasseMonitoramento }]
  }
  const _getRepasseContaOrigem = () => {
    if (contaOrigem?.CNPJ === '07.110.727/0001-70' || contaOrigem?.CNPJ === '04.197.488/0001-40') {
      return []
    }
    const contaSocia = _getDadosContaSocia()
    if (!possuiRepasseOrigem) return []
    const valorRepasseOrigem = municipio === 'JATAÍ' ? 100 : 200
    const valor = contaSocia ? valorRepasseOrigem + contaSocia.Desconto : valorRepasseOrigem
    return [{ descricao: contaOrigem?.Fantasia ?? <em>CFC de Origem</em>, valor }]
  }
  // #endregion

  const { Container } = Repasses

  useEffect(() => {
    if (possuiRepasseOrigem) localStorage.setItem('possui-repasse-origem', 'true')
    else localStorage.removeItem('possui-repasse-origem')
  }, [possuiRepasseOrigem])

  const valorRepasseMonitoramento = (contaDestino?.repasses ?? [])
    .filter(x => x.Valor)
    .reduce((prev, curr) => prev + curr.Valor, 0)

  const repasseDestino = _getRepasseContaDestino({ valorRepasseMonitoramento })

  const repasseMonitoramento = valorRepasseMonitoramento
    ? [{ descricao: 'Monitoramento', valor: valorRepasseMonitoramento }]
    : []

  const repasseOrigem = _getRepasseContaOrigem()

  const valorTarifas = municipio === 'JATAÍ' ? 6 : 4.5

  const repasses = [
    { descricao: 'Sindicato', valor: 2.5 },
    { descricao: 'Tarifas', valor: valorTarifas },
    ...repasseMonitoramento,
    ...repasseDestino,
    ...repasseOrigem,
  ]

  const valorTotal = _formatMoeda(repasses.reduce((prev, curr) => prev + curr.valor, 0))
  repasses.forEach(r => { r.valor = _formatMoeda(r.valor) })

  return (
    <Container className="repasses">
      {repasses.map((r, i) => <Repasse repasse={r} key={i} />)}
      <Repasse repasse={{ valor: <strong>{valorTotal}</strong>, descricao: <strong>Valor Total</strong> }} />
    </Container>
  )
}
Repasses.Container = styled.div`
  width: 250px;
  justify-self: end;
`

const Repasse = ({ repasse }) => {
  const { Container, Separador } = Repasse
  return (
    <Container>
      <div> {repasse.descricao} </div>
      <Separador />
      <div> {repasse.valor} </div>
    </Container>
  )
}
Repasse.Container = styled.div`
  display: flex;
`
Repasse.Separador = styled.div`
  flex: 1;
  border-bottom: 1px dashed #ccc;
  top: -5px;
  position: relative;
  margin: 0 8px;
`

const Cpf = () => {
  const { cpf } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <div className="cpf">
      <label>
        CPF
        <TextFieldInput
          mask="cpf"
          value={cpf}
          onChange={e => dispatch(Store.actions.changeCpf(e.target.value))}
          placeholder="Informe o CPF do aluno"
          size="large"
        />
      </label>
    </div>
  )
}

const Nome = () => {
  const { nome } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <div className="nome">
      <label>
        Nome
        <Input
          value={nome}
          onChange={e => dispatch(Store.actions.changeNome(e.target.value))}
          placeholder="Informe o nome do aluno"
          style={nome ? { textTransform: 'uppercase' } : {}}
          size="large"
        />
      </label>
    </div>
  )
}

const GerarBoleto = () => {
  const dispatch = Store.useDispatch()
  const state = Store.useState()
  const [{ loading }, fetch] = useAxios(
    { url: 'gerar-boleto-teorico', method: 'post' },
    { manual: true }
  )

  function validate () {
    const removeAccents = value => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    // preenchidos
    if (!state.contaDestino) return 'Informe o CFC de destino'
    if (!state.contaOrigem) return 'Informe o CFC de origem'
    if (!state.cpf) return 'Informe o CPF do aluno'
    if (!state.nome) return 'Informe o Nome do aluno'
    // cfc origem mesma cidade q destino
    if (removeAccents(state.contaDestino.Municipio) !== removeAccents(state.contaOrigem.Municipio)) {
      return 'CFC de Origem fica em uma cidade diferente do CFC de Destino'
    }

    // cpf valido
    if (!isCpfValid(state.cpf)) return 'CPF Inválido'
  }

  async function handleClick () {
    const message = validate()
    if (message) return notification.error({ message })

    try {
      const boleto = await fetch({
        data: {
          contaDestinoId: state.contaDestino.Id,
          contaOrigemId: state.contaOrigem.Id,
          cpf: state.cpf,
          nome: state.nome,
          repasseCfcOrigem: state.possuiRepasseOrigem,
        },
      }).then(x => x?.data)
      Modal.confirm({
        content: 'Boleto gerado com sucesso. Clique em Visualizar para exibir o boleto',
        onOk () {
          window.open(boleto.Boleto_URL)
        },
        okText: 'Visualizar',
        cancelButtonProps: {
          style: { display: 'none' },
        },
      })
      dispatch(Store.actions.clear())
    } catch (error) {
      const message = error.response?.data?.message
      notification.error({ message })
    }
  }

  return (
    <Styles.GerarBoleto>
      <Button onClick={handleClick} loading={!!loading} size="large" block type="primary">Gerar Boleto</Button>
    </Styles.GerarBoleto>
  )
}

const _formatMoeda = valor => {
  if (!valor || isNaN(valor)) return valor
  return valor.toFixed(2).replace('.', ',')
}

const Styles = {
  GerarBoleto: styled('div')`
    max-width: 800px;
    margin: 0 auto;
  `,
}

export {
  BoletoTeorico,
}
